<template>
  <div>
    <div
      :class="[$style.countriesSVGContainer, selectedTrack && $style.selected]"
    >
      <CountriesSVG ref="countriesSVG" :class="[$style.countriesSVG]" />
    </div>
    <slot
      :openCountryDetails="openCountryDetails"
      :searchCountries="searchCountries"
      :highlightCountries="highlightCountries"
      :resetCountriesColour="resetCountriesColour"
      :closeCountryDetailsCard="closeCountryDetailsCard"
      :closeCompareCard="closeCompareCard"
    />
    <div :class="[$style.detailsCardsSection, isComparing && $style.comparing]">
      <div
        v-if="showCountryDetails"
        :class="[$style.detailsCardContainer, isComparing && $style.comparing]"
      >
        <DetailsCard
          :sources="countryDetails"
          :fetchCountryDataStatus="
            isComparing ? API_STATUS.SUCCESS : fetchCountryDataStatus
          "
          :showCountryDetails="showCountryDetails"
          :selectedSource="selectedSource"
          :selectedCompareCountry="selectedCompareCountry"
          :selectedCompareSource="selectedCompareSource"
          @close="closeCountryDetailsCard"
          @compare="isComparing = !isComparing"
          :isComparing="isComparing"
          isOriginalCard
          :countriesData="countriesData"
          :initialSource="initialSource"
        />
      </div>
      <div
        v-if="isComparing"
        :class="[
          $style.detailsCardCompareContainer,
          isComparing && $style.comparing,
        ]"
      >
        <DetailsCard
          :sources="countryDetailsCompare"
          :fetchCountryDataStatus="fetchCountryDataStatus"
          :showCountryDetails="showCountryDetails"
          :selectedSource="selectedSource"
          :isComparing="isComparing"
          :countriesTags="countriesTags"
          :isOriginalCard="false"
          :countriesData="countriesData"
          :selectedCompareCountry="selectedCompareCountry"
          :selectedCompareSource="selectedCompareSource"
          @close="closeCountryDetailsCard"
          @compare="isComparing = !isComparing"
          @fetchCountryDetails="onFetchCompareCountryDetails"
          @onChangeCompareSource="onChangeCompareSource"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CountriesSVG from "../../world/worldSearch/countries.svg?inline";

import { API_STATUS } from "@/constants/apiStatus";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { withAsync } from "@/helpers";
import {
  searchWorkRegistrations,
  searchWorkRegistrationsByCountry,
} from "@/api/workRegistrationApi";
import DetailsCard from "./DetailsCard";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  name: "TrackDetailsProvider",
  components: {
    DetailsCard,
    CountriesSVG,
  },
  props: {
    selectedTrack: {
      type: Object,
    },
  },
  created() {
    this.API_STATUS = API_STATUS;
  },
  data() {
    return {
      fetchCountryDataStatus: IDLE,
      searchWorkRegistrationsStatus: IDLE,
      countriesData: {},
      showCountryDetails: false,
      selectedCountry: false,
      selectedSource: null,
      selectedCompareCountry: null,
      selectedCompareSource: null,
      isComparing: false,
      countriesByIswc: {},
      iswc: null,
      initialSource: null,
      isMapZoomedIn: false,
    };
  },
  computed: {
    ...apiStatusComputedFactory("searchWorkRegistrationsStatus"),
    ...apiStatusComputedFactory("fetchCountryDataStatus"),
    countries() {
      return this.countriesByIswc[this.iswc] || [];
    },
    countryDetails() {
      return this.countriesData[this.selectedCountry];
    },
    countryDetailsCompare() {
      return this.countriesData[this.selectedCompareCountry];
    },
    countriesTags() {
      let data = {};
      for (const item of this.countries) {
        if (item.source in data) {
          data[item.source].occurences++;
        } else {
          data[item.source] = {
            ...item,
            occurences: 1,
          };
        }
      }

      return Object.values(data);
    },
  },
  methods: {
    closeCompareCard() {
      this.isComparing = false;
    },
    resetCountriesColour(countries = this.countriesColoursChanged) {
      if (!Array.isArray(countries)) return;
      this.changeCountriesColour(
        countries.map((country) => ({ ...country, countryColour: "#EDEEF3" }))
      );
      this.countriesColoursChanged = [];
    },
    highlightCountries(countries) {
      this.countriesColoursChanged = countries;
      this.changeCountriesColour(countries, "#B5B9CC");
    },
    changeCountriesColour(countries, colour) {
      for (const { country_tag: country, countryColour } of countries) {
        try {
          const $countryEl = this.$refs.countriesSVG.querySelector(
            `#${country}`
          );
          if (!$countryEl) continue;
          const useEl = $countryEl.childNodes[1];
          useEl.setAttribute("fill", countryColour || colour);
          $countryEl.addEventListener("click", this.onCountryClick);
        } catch (error) {
          console.error(error);
        }
      }
    },
    onCountryClick(e) {
      const country = e.currentTarget.getAttribute("id");
      this.openCountryDetails({
        track: {
          ...this.selectedTrack,
          country_tag: country,
        },
        source: this.selectedTrack.source,
      });
    },
    cleanupCode(code) {
      return code.replaceAll("-", "").replaceAll(".", "");
    },
    async openCountryDetails({ track, source }) {
      const { country_tag: country, iswc: code } = track;

      if (!this.countriesByIswc[code]) {
        await this.searchCountries(code);
      }
      await this.fetchCountryDetails(code, country);

      if (this.selectedSource === source) return;
      this.selectedSource = source;
      if (this.selectedCountry === country) return;
      this.iswc = code;
      this.selectedCountry = country;
      this.zoomInCountry(country);
      this.showCountryDetails = true;
    },
    getElCenterPosition(boundingRect) {
      const { left, top, width, height } = boundingRect;
      return {
        centerX: left + width / 2,
        centerY: top + height / 2,
      };
    },
    async zoomInCountry(country) {
      const $container = this.$refs.countriesSVG;
      const $country = this.$refs.countriesSVG.querySelector(`#${country}`);
      if (this.isMapZoomedIn) {
        let transformPromise = new Promise((resolve) => {
          const onTransitionEnd = () => {
            $container.removeEventListener("ontransitionend", onTransitionEnd);
            setTimeout(() => {
              resolve();
            }, 250);
          };
          $container.addEventListener("transitionend", onTransitionEnd);
          $container.style.transform = `scale(1) translate(0px, 0px)`;
        });
        await transformPromise;
      }

      const countryBoundingRect = $country.getBoundingClientRect();
      const containerBoundingRect = $container.getBoundingClientRect();

      const countryCenter = this.getElCenterPosition(countryBoundingRect);

      let startCenter = this.getElCenterPosition(containerBoundingRect);
      let translateX = startCenter.centerX - countryCenter.centerX;
      let translateY = startCenter.centerY - countryCenter.centerY;

      $container.style.transform = `scale(3.5) translate(${
        translateX + 100
      }px, ${translateY + 50}px)`;
      this.isMapZoomedIn = true;
    },
    closeCountryDetailsCard() {
      this.showCountryDetails = false;
      this.selectedCountry = null;
      this.selectedSource = null;
      this.$refs.countriesSVG.style.transform = `scale(1) translate(0px, 0px)`;
      this.isMapZoomedIn = false;
    },
    onChangeCompareSource({ country_tag, source }) {
      this.selectedCompareCountry = country_tag;
      this.selectedCompareSource = source;
    },
    onFetchCompareCountryDetails({ country_tag: country, source }) {
      this.selectedCompareCountry = country;
      this.selectedCompareSource = source;
      this.fetchCountryDetails(this.iswc, country);
    },
    async searchCountries(code) {
      if (!code) return;
      this.searchWorkRegistrationsStatus = PENDING;
      const { response, error } = await withAsync(() =>
        searchWorkRegistrations(this.cleanupCode(code))
      );

      if (error) {
        console.error(error);
        this.searchWorkRegistrationsStatus = ERROR;
        return;
      }

      const countries = response.filter(
        ({ country_tag }) => country_tag !== "-"
      );

      this.$set(this.countriesByIswc, code, countries);
      // this.highlightCountries(this.countries);
      this.searchWorkRegistrationsStatus = SUCCESS;
      return countries;
    },
    async fetchCountryDetails(code, country) {
      this.fetchCountryDataStatus = PENDING;
      const { response, error } = await withAsync(() =>
        searchWorkRegistrationsByCountry(this.cleanupCode(code), country)
      );

      if (error) {
        this.fetchCountryDataStatus = ERROR;
        return;
      }
      const countryDetails = response.reduce((acc, item) => {
        const {
          source,
          source_name: sourceName,
          country,
          society = "NULL",
          workcode,
          originalTitle: title,
          iswc,
          createdDate,
          lastModifiedDate,
        } = item;
        const societyName = society ? society : "-";
        let key = `${source}-${workcode}`;
        if (!(key in acc)) {
          acc[key] = {
            source,
            sourceName,
            country,
            workcode,
            title,
            societyName,
            createdDate: new Intl.DateTimeFormat("en-US").format(
              new Date(createdDate)
            ),
            lastModifiedDate: new Intl.DateTimeFormat("en-US").format(
              new Date(lastModifiedDate)
            ),
            iswc: iswc,
            items: [],
          };
        }

        acc[key].items.push({
          ...item,
          createdDate: new Intl.DateTimeFormat("en-US").format(
            new Date(createdDate)
          ),
          lastModifiedDate: new Intl.DateTimeFormat("en-US").format(
            new Date(lastModifiedDate)
          ),
        });
        return acc;
      }, {});
      this.$set(this.countriesData, country, countryDetails);
      this.fetchCountryDataStatus = SUCCESS;
    },
  },
};
</script>

<style lang="scss" module>
.countriesSVG {
  transition: 1s all ease-in-out;
  will-change: transform;
  outline: none;
}
.countriesSVGContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s all ease-in-out;
  will-change: transform;
  z-index: -1;

  &.selected {
    z-index: 5;
  }
}

.detailsCardsSection {
  pointer-events: none;
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-height: 100vh;
  position: fixed;
  z-index: 100;
  inset: 0;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  @media (min-width: 1280px) {
    &.comparing {
      gap: 2rem;
    }
  }
}
</style>